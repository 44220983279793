import { Injectable } from '@angular/core';
import { DEFAULT_LIMIT, DEFAULT_OFFSET, MASKED_FLAG } from '@configs/constants';
import { NotificationService } from '@core/services/notification/notification.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  ACCEPT_BID_OPTION_MUTATION,
  COMPLETE_TENDER_MUTATION,
  FETCH_LONG_TERM_BID_BY_ID_QUERY,
  FETCH_LONG_TERM_BIDS_BY_TENDER_ID_QUERY,
  FETCH_SPOT_BID_BY_ID_QUERY,
  FETCH_SPOT_BIDS_BY_TENDER_ID_QUERY,
  GET_TOTAL_BIDS_BY_TENDER_ID_QUERY,
  MARK_BID_AS_FAVORITE_MUTATION,
  REJECT_ALL_BIDS_MUTATION,
  REJECT_BID_MUTATION,
  VOTE_BID_OPTION_MUTATION,
  WITHDRAW_BID_MUTATION,
} from '@shared/gql-shared-queries';
import { Apollo } from 'apollo-angular';
import { catchError, of, tap } from 'rxjs';
import { Bid, VoteStatus } from '../../dto/bid.dto';
import { TenderStates } from '../../enums/tender-status.enum';
import { TenderTypes } from '../../enums/tender-type.enum';
import {
  AwardBidAction,
  ClearBids,
  CompleteTenderAction,
  FetchAwardedBids,
  FetchBidByIdAction,
  FetchBidsByTenderIdAction,
  FetchBidsPaginated,
  FetchFavoriteBidsPaginated,
  FetchNonFavoriteBidsPaginated,
  FetchTotalBidsByTenderId,
  MarkAsFavoriteBidAction,
  RejectAllBidsAction,
  RejectBidAction,
  SetCurrentVoteAction,
  SetTenderTypeAction,
  UpdateTenderStatusAction,
  VoteBidAction,
  WithdrawBidAction,
} from './procurement.actions';

export interface ProcurementStateModel {
  bids: {
    items: Bid[];
    pageInfo?: {
      total: number;
      pageSize: number;
      hasPreviousPage: boolean;
      hasNextPage: boolean;
    };
  };
  favoriteBids: {
    items: Bid[];
    pageInfo?: {
      total: number;
      pageSize: number;
      hasPreviousPage: boolean;
      hasNextPage: boolean;
    };
  };
  acceptedBids: Bid[];
  currentBid: Bid;
  currentTenderStatus: TenderStates;
  currentVote: VoteStatus;
  totalBidsForTender: number;
  tenderType: TenderTypes | null;
}

const defaults: ProcurementStateModel = {
  bids: {
    items: [],
    pageInfo: {
      total: 0,
      pageSize: DEFAULT_LIMIT,
      hasPreviousPage: false,
      hasNextPage: false,
    },
  },
  favoriteBids: {
    items: [],
    pageInfo: {
      total: 0,
      pageSize: DEFAULT_LIMIT,
      hasPreviousPage: false,
      hasNextPage: false,
    },
  },
  acceptedBids: [],
  currentBid: null,
  currentTenderStatus: TenderStates.PUBLISHED,
  currentVote: null,
  totalBidsForTender: 0,
  tenderType: null,
};

@State<ProcurementStateModel>({
  name: 'procurement',
  defaults,
  children: [],
})
@Injectable()
export class ProcurementState {
  constructor(
    private apollo: Apollo,
    private notificationService: NotificationService
  ) {}

  @Selector()
  static getBids(state: ProcurementStateModel) {
    return state.bids.items;
  }

  @Selector()
  static getFavoriteBids(state: ProcurementStateModel) {
    return state.favoriteBids.items;
  }

  @Selector()
  static getAwardedBids(state: ProcurementStateModel) {
    return state.acceptedBids;
  }

  @Selector()
  static getCurrentBid(state: ProcurementStateModel) {
    return state.currentBid;
  }

  @Selector()
  static getCurrentTenderStatus(state: ProcurementStateModel): TenderStates {
    return state.currentTenderStatus;
  }

  @Selector()
  static getCurrentVote(state: ProcurementStateModel) {
    return state.currentVote;
  }

  @Selector()
  static getTotalBidsForTender(state: ProcurementStateModel) {
    return state.totalBidsForTender;
  }

  @Selector()
  static getTotalAllBids(state: ProcurementStateModel) {
    return state.bids.pageInfo.total;
  }

  @Selector()
  static getTotalFavoriteBids(state: ProcurementStateModel) {
    return state.favoriteBids.pageInfo.total;
  }

  @Selector()
  static getTenderType(state: ProcurementStateModel) {
    return state.tenderType;
  }

  @Action(SetTenderTypeAction)
  setTenderType({ patchState }: StateContext<ProcurementStateModel>, { tenderType }: SetTenderTypeAction) {
    patchState({
      tenderType,
    });
  }

  @Action(FetchTotalBidsByTenderId)
  fetchTotalBidsByTenderId(
    { patchState }: StateContext<ProcurementStateModel>,
    { tenderId }: FetchTotalBidsByTenderId
  ) {
    return this.apollo
      .query({
        query: GET_TOTAL_BIDS_BY_TENDER_ID_QUERY,
        variables: { tenderId },
      })
      .pipe(
        tap(response => {
          patchState({ totalBidsForTender: response.data['getTotalBidsByTenderId'] });
        })
      );
  }

  @Action(FetchBidByIdAction)
  fetchBidById({ getState, patchState }: StateContext<ProcurementStateModel>, { bidId }: FetchBidByIdAction) {
    if (!bidId) {
      return of(null);
    }

    let fetchBidQuery = FETCH_SPOT_BID_BY_ID_QUERY;
    if (getState().tenderType === TenderTypes.LONG_TERM_TENDER) {
      fetchBidQuery = FETCH_LONG_TERM_BID_BY_ID_QUERY;
    }

    return this.apollo
      .query({
        query: fetchBidQuery,
        variables: { bidId },
      })
      .pipe(
        tap(response => {
          const currentBid = response.data['findBidById'];
          patchState({
            currentBid,
          });
        }),
        catchError(err => {
          console.error('Error Fetching Bid', err);
          return of(null);
        })
      );
  }

  @Action(FetchBidsPaginated)
  fetchBidsPaginated(
    { patchState, getState }: StateContext<ProcurementStateModel>,
    { tenderId, offset, limit, sortOption, searchText, favorite, accepted }: FetchBidsPaginated
  ) {
    if (!tenderId) {
      return of(null);
    }

    // TODO: This logic needs to be refactored and taken out into a factory service during the refactor phase
    let fetchBidsQuery = FETCH_SPOT_BIDS_BY_TENDER_ID_QUERY;
    if (getState().tenderType === TenderTypes.LONG_TERM_TENDER) {
      fetchBidsQuery = FETCH_LONG_TERM_BIDS_BY_TENDER_ID_QUERY;
    }

    return this.apollo
      .query({
        query: fetchBidsQuery,
        variables: {
          tenderId,
          favorite,
          accepted,
          offset,
          limit,
          sortOption,
          searchText,
          excludeStatuses: ['WITHDRAWN'],
        },
      })
      .pipe(
        tap(response => {
          const bids: Bid[] = response.data['findPaginatedBidsByTenderId']['items'];
          const pageInfo = response.data['findPaginatedBidsByTenderId']['pageInfo'];

          const transformedBids = bids.map(bid => this.formatBidDetails(bid, getState().tenderType));

          const stateKey = favorite ? 'favoriteBids' : 'bids';
          patchState({
            [stateKey]: {
              items: transformedBids,
              pageInfo,
            },
          });
        }),
        catchError(err => {
          console.error(`Error Fetching ${favorite ? 'Favorite' : 'Non-Favorite'} Bids`, err);
          return of(null);
        })
      );
  }

  @Action(FetchNonFavoriteBidsPaginated)
  fetchNonFavoriteBidsPaginated(ctx: StateContext<ProcurementStateModel>, payload: FetchNonFavoriteBidsPaginated) {
    return this.fetchBidsPaginated(ctx, { ...payload, favorite: false, accepted: false });
  }

  @Action(FetchFavoriteBidsPaginated)
  fetchFavoriteBidsPaginated(ctx: StateContext<ProcurementStateModel>, payload: FetchFavoriteBidsPaginated) {
    return this.fetchBidsPaginated(ctx, { ...payload, favorite: true, accepted: false });
  }

  @Action(FetchAwardedBids)
  fetchAwardedBid({ getState, patchState }: StateContext<ProcurementStateModel>, { tenderId }: FetchAwardedBids) {
    if (!tenderId) {
      return of(null);
    }
    return this.apollo
      .query({
        query: FETCH_SPOT_BIDS_BY_TENDER_ID_QUERY,
        variables: { tenderId, favorite: true, accepted: true, offset: DEFAULT_OFFSET, limit: DEFAULT_LIMIT },
      })
      .pipe(
        tap(response => {
          const awardedBids: Bid[] = response.data['findPaginatedBidsByTenderId']['items'];
          patchState({ acceptedBids: awardedBids.map(bid => this.formatBidDetails(bid, getState().tenderType)) });
        }),
        catchError(err => {
          console.error('Error Fetching Awarded Bid', err);
          return of(null);
        })
      );
  }

  /**
   * @deprecated - This action is deprecated and will be removed in the future therefore change all usages of this action
   *  to fetchBidsPaginated. Do not use this in new code.
   */
  @Action(FetchBidsByTenderIdAction)
  fetchBidByTenderId(
    { getState, patchState }: StateContext<ProcurementStateModel>,
    { tenderId, orgId }: FetchBidsByTenderIdAction
  ) {
    if (!tenderId) {
      return of(null);
    }

    let fetchBidsQuery = FETCH_SPOT_BIDS_BY_TENDER_ID_QUERY;
    if (getState().tenderType === TenderTypes.LONG_TERM_TENDER) {
      fetchBidsQuery = FETCH_LONG_TERM_BIDS_BY_TENDER_ID_QUERY;
    }

    return this.apollo
      .query({
        query: fetchBidsQuery,
        variables: {
          tenderId,
          favorite: false,
          accepted: false,
          offset: DEFAULT_OFFSET,
          limit: 100,
          ...(orgId ? { orgId } : {}),
        },
      })
      .pipe(
        tap(response => {
          const bids: Bid[] = response.data['findPaginatedBidsByTenderId']['items'];
          const bidItems: Bid[] = [];
          const favoriteBids: Bid[] = [];
          const acceptedBids: Bid[] = [];

          for (const bid of bids) {
            const date = new Date(bid.createdAt);
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const time = `${hours}:${minutes}`;
            let totalAmount = 0;

            bid.bidMetaData?.shipmentsData?.forEach(shipment => {
              shipment.serviceMetaData?.forEach(service => {
                if (service.ratesSummary) {
                  totalAmount += service.ratesSummary['total'];
                } else {
                  totalAmount = MASKED_FLAG;
                }
              });
            });

            const tempBid = {
              ...bid,
              date: date.toISOString().split('T')[0],
              time: time,
              bidId: bid._id,
              totalAmount,
            };
            bidItems.push(tempBid);

            if (bid.bidMetaData.favorite) {
              favoriteBids.push(tempBid);
            }
            if (bid.bidMetaData.accepted) {
              acceptedBids.push(tempBid);
            }
          }
          patchState({
            bids: {
              items: bidItems,
            },
            favoriteBids: {
              items: favoriteBids,
            },
            acceptedBids: acceptedBids.map(bid => this.formatBidDetails(bid, getState().tenderType)),
          });
        }),
        catchError(err => {
          console.error('Error Fetching Bids', err);
          return of(null);
        })
      );
  }

  @Action(MarkAsFavoriteBidAction)
  async saveMarkAsFavoriteBid(
    { getState, patchState }: StateContext<ProcurementStateModel>,
    { bidId, metaData = {} }: MarkAsFavoriteBidAction
  ) {
    if (!bidId) {
      return of(null);
    }

    return this.apollo
      .mutate({
        mutation: MARK_BID_AS_FAVORITE_MUTATION,
        variables: {
          bidId,
          metaData,
        },
      })
      .pipe(
        catchError(async err => {
          this.notificationService.showError('An error occurred while marking as favorite');
          console.error('Error marking bid as favorite', err);
          if (getState().tenderType === TenderTypes.LONG_TERM_TENDER) {
            this.notificationService.showError('An error occurred while marking location pair as favorite');
          }
          if (getState().tenderType === TenderTypes.SPOT_TENDER) {
            this.notificationService.showError('An error occurred while marking bid as favorite');
          }
          return of(null);
        })
      );
  }

  @Action(AwardBidAction)
  async awardBidOption(
    { getState, patchState }: StateContext<ProcurementStateModel>,
    { bidId, bidOptionId }: AwardBidAction
  ) {
    if (!bidId || !bidOptionId) {
      return of(null);
    }
    return this.apollo
      .mutate({
        mutation: ACCEPT_BID_OPTION_MUTATION,
        variables: {
          bidId,
          bidOptionId,
        },
      })
      .pipe(
        catchError(err => {
          console.error('error awarding bid', err);
          if (getState().tenderType === TenderTypes.LONG_TERM_TENDER) {
            this.notificationService.showError('An error occurred while awarding the location pair');
          }
          if (getState().tenderType === TenderTypes.SPOT_TENDER) {
            this.notificationService.showError('An error occurred while awarding the bid');
          }
          return of(null);
        })
      );
  }

  @Action(VoteBidAction)
  async voteBidOption(
    { getState, patchState }: StateContext<ProcurementStateModel>,
    { bidId, metaData = {} }: VoteBidAction
  ) {
    if (!bidId) {
      return of(null);
    }
    const currentVote = getState().currentVote;
    if (!currentVote) {
      patchState({
        currentVote: {
          bidId,
          voted: true,
        },
      });
    } else if (currentVote.bidId === bidId) {
      patchState({
        currentVote: null,
      });
    }
    return this.apollo
      .mutate({
        mutation: VOTE_BID_OPTION_MUTATION,
        variables: {
          bidId,
          metaData,
        },
      })
      .pipe(
        catchError(async err => {
          console.error('error voting bid', err);
          if (getState().tenderType === TenderTypes.LONG_TERM_TENDER) {
            this.notificationService.showError('An error occurred while voting for the location pair');
          }
          if (getState().tenderType === TenderTypes.SPOT_TENDER) {
            this.notificationService.showError('An error occurred while voting for the bid');
          }
          return of(null);
        })
      );
  }

  @Action(WithdrawBidAction)
  async withdrawBid({ getState, patchState }: StateContext<ProcurementStateModel>, { bidId }: WithdrawBidAction) {
    return this.apollo.mutate({ mutation: WITHDRAW_BID_MUTATION, variables: { bidId } }).pipe(
      tap(response => {
        const withdrawn = response.data['withdrawBid'];
        if (withdrawn) {
          patchState({
            bids: {
              items: getState().bids.items.filter(bid => {
                if (bid.bidId === bidId) {
                  return {
                    ...bid,
                    status: 'WITHDRAWN',
                  };
                }
                return bid;
              }),
            },
          });
        }
        console.log('withdrawn', withdrawn);
      }),
      catchError(async err => console.error('error withdrawing bid', err))
    );
  }

  @Action(UpdateTenderStatusAction)
  updateTenderStatus({ patchState }: StateContext<ProcurementStateModel>, { tenderStatus }: UpdateTenderStatusAction) {
    patchState({
      currentTenderStatus: tenderStatus,
    });
  }

  @Action(SetCurrentVoteAction)
  setCurrentVote({ patchState }: StateContext<ProcurementStateModel>, { currentVote }: SetCurrentVoteAction) {
    patchState({
      currentVote,
    });
  }

  // @Action(Logout)
  // logout({ patchState }: StateContext<ProcurementStateModel>) {
  //   patchState({
  //     currentVote: null,
  //   });
  // }

  @Action(RejectAllBidsAction)
  rejectAllBids(_ctx: StateContext<ProcurementStateModel>, { tenderId, rejectionReason }: RejectAllBidsAction) {
    return this.apollo
      .mutate<boolean>({
        mutation: REJECT_ALL_BIDS_MUTATION,
        variables: { tenderId, reason: rejectionReason },
      })
      .pipe(
        tap(result => {
          if (result) {
            this.notificationService.showSuccess('All Bids Rejected Successfully');
          } else {
            this.notificationService.showError('Rejecting All Bids was Unsuccessful');
          }
        }),
        catchError(err => {
          console.error('error rejecting all bids', err);
          this.notificationService.showError('An Error Occurred While Rejecting All Bids');
          return of(null);
        })
      );
  }

  @Action(RejectBidAction)
  rejectBid(
    { getState, patchState }: StateContext<ProcurementStateModel>,
    { bidId, rejectionReason }: RejectBidAction
  ) {
    return this.apollo.mutate({ mutation: REJECT_BID_MUTATION, variables: { bidId, reason: rejectionReason } }).pipe(
      tap(result => {
        if (result) {
          this.notificationService.showSuccess('Bid Rejected Successfully');
        } else {
          this.notificationService.showError('Rejecting Bid was Unsuccessful');
        }
      }),
      catchError(async err => console.error('error rejecting bid', err))
    );
  }

  @Action(ClearBids)
  clearBids({ patchState }: StateContext<ProcurementStateModel>) {
    patchState(defaults);
  }

  @Action(CompleteTenderAction)
  completeTender({ patchState }: StateContext<ProcurementStateModel>, { tenderId }: CompleteTenderAction) {
    return this.apollo.mutate({ mutation: COMPLETE_TENDER_MUTATION, variables: { tenderId } }).pipe(
      tap(result => {
        if (result) {
          this.notificationService.showSuccess('Tender Completed Successfully');
          patchState({
            currentTenderStatus: TenderStates.AWARDED,
          });
        } else {
          this.notificationService.showError('Completing Tender was Unsuccessful');
        }
      })
    );
  }

  /*
   * Formats a single bid object from the query response to match the UI requirements
   */
  private formatBidDetails(bid: Bid, tenderType: TenderTypes): Bid {
    const date = new Date(bid.createdAt);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const time = `${hours}:${minutes}`;
    let totalAmount = 0;

    // TODO: Refactor
    if (tenderType === TenderTypes.SPOT_TENDER) {
      bid.bidMetaData.shipmentsData.forEach(shipment => {
        shipment.serviceMetaData.forEach(service => {
          if (service.ratesSummary) {
            totalAmount += service.ratesSummary['total'];
          } else {
            totalAmount = MASKED_FLAG;
          }
        });
      });
    }

    return {
      ...bid,
      date: date.toISOString().split('T')[0],
      time: time,
      bidId: bid._id,
      totalAmount,
    };
  }
}
